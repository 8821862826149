.root {
  height: 100%;
}

.main {
  @mixin light {
    background-color: var(--mantine-color-gray-0);
  }
  @mixin dark {
    background-color: var(--mantine-color-dark-8);
  }
}

.header {
  @mixin light {
    background-color: var(--mantine-color-body);
    border-color: var(--mantine-color-gray-0);
  }
  @mixin dark {
    background-color: var(--mantine-color-body);
    border-color: var(--mantine-color-dark-8);
  }
}

.navbar {
  @mixin light {
    background-color: var(--mantine-color-gray-0);
    border-color: var(--mantine-color-body);
  }
  @mixin dark {
    background-color: var(--mantine-color-dark-8);
    border-color: var(--mantine-color-body);
  }
}
