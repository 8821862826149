.m-17884d0f {
  --carousel-height: auto;
  position: relative;
}

.m-a2dae653 {
  height: var(--carousel-height);
  overflow: hidden;
}

.m-fcd81474 {
  display: flex;
  flex-direction: row;
  height: var(--carousel-height);
}

:where([data-include-gap-in-size]) .m-fcd81474:where([data-orientation='vertical']) {
      margin-bottom: calc(var(--carousel-slide-gap) * -1);
    }

:where([data-include-gap-in-size]) .m-fcd81474:where([data-orientation='horizontal']) {
      margin-inline-end: calc(var(--carousel-slide-gap) * -1);
    }

.m-fcd81474:where([data-orientation='vertical']) {
    flex-direction: column;
  }

.m-39bc3463 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  pointer-events: none;
}

.m-17884d0f:where([data-orientation='vertical']) .m-39bc3463 {
    inset-inline-start: calc(50% - var(--carousel-control-size) / 2);
    top: 0;
    bottom: 0;
    flex-direction: column;
    padding: var(--carousel-controls-offset) 0;
  }

.m-17884d0f:where([data-orientation='horizontal']) .m-39bc3463 {
    inset-inline-start: 0;
    inset-inline-end: 0;
    top: calc(50% - var(--carousel-control-size) / 2);
    flex-direction: row;
    padding: 0 var(--carousel-controls-offset);
  }

.m-64f58e10 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: var(--carousel-control-size);
  min-height: var(--carousel-control-size);
  border-radius: var(--carousel-control-size);
  pointer-events: all;
  background-color: var(--mantine-color-white);
  color: var(--mantine-color-black);
  box-shadow: var(--mantine-shadow-md);
  border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-3);
  transition: opacity 100ms ease;
}

:where([data-mantine-color-scheme='light']) .m-64f58e10 {
    opacity: 0.85;
}

:where([data-mantine-color-scheme='dark']) .m-64f58e10 {
    opacity: 0.65;
}

@media (hover: hover) {

  .m-64f58e10:hover {
    opacity: 1;
  }
}

@media (hover: none) {

  .m-64f58e10:active {
    opacity: 1;
  }
}

.m-71ea3ab1 {
  position: absolute;
  display: flex;
  justify-content: center;
  gap: calc(0.5rem * var(--mantine-scale));
  pointer-events: none;
}

.m-17884d0f:where([data-orientation='vertical']) .m-71ea3ab1 {
    bottom: 0;
    top: 0;
    inset-inline-end: var(--mantine-spacing-md);
    flex-direction: column;
  }

.m-17884d0f:where([data-orientation='horizontal']) .m-71ea3ab1 {
    bottom: var(--mantine-spacing-md);
    inset-inline-start: 0;
    inset-inline-end: 0;
    flex-direction: row;
  }

.m-eae68602 {
  pointer-events: all;
  border-radius: var(--mantine-radius-xl);
  box-shadow: var(--mantine-shadow-sm);
  opacity: 0.6;
  background-color: var(--mantine-color-white);
}

@media (hover: hover) {

  .m-eae68602:hover {
    opacity: 1;
  }
}

@media (hover: none) {

  .m-eae68602:active {
    opacity: 1;
  }
}

.m-eae68602:where([data-active]) {
    opacity: 1;
  }

.m-17884d0f:where([data-orientation='vertical']) .m-eae68602 {
    width: calc(0.3125rem * var(--mantine-scale));
    height: calc(1.5625rem * var(--mantine-scale));
  }

.m-17884d0f:where([data-orientation='horizontal']) .m-eae68602 {
    width: calc(1.5625rem * var(--mantine-scale));
    height: calc(0.3125rem * var(--mantine-scale));
  }

.m-d98df724 {
  position: relative;
  flex: 0 0 var(--carousel-slide-size);
}

.m-17884d0f:where([data-include-gap-in-size]) .m-d98df724:where([data-orientation='vertical']) {
      padding-bottom: var(--carousel-slide-gap);
    }

.m-17884d0f:where([data-include-gap-in-size]) .m-d98df724:where([data-orientation='horizontal']) {
      padding-inline-end: var(--carousel-slide-gap);
    }

.m-17884d0f:where(:not([data-include-gap-in-size])) .m-d98df724:where([data-orientation='vertical']) {
      margin-bottom: var(--carousel-slide-gap);
    }

.m-17884d0f:where(:not([data-include-gap-in-size])) .m-d98df724:where([data-orientation='horizontal']) {
      margin-inline-end: var(--carousel-slide-gap);
    }

.m-e4d36c9b {
  min-width: calc(12.5rem * var(--mantine-scale));
  background-color: var(--mantine-color-body);
  box-shadow: var(--mantine-shadow-md);
  border-radius: var(--mantine-radius-default);
}

  :where([data-mantine-color-scheme='light']) .m-e4d36c9b {
    border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-3);
}

  :where([data-mantine-color-scheme='dark']) .m-e4d36c9b {
    border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-4);
}

.m-7f4bcb19 {
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  font-size: var(--mantine-font-size-md);
  font-weight: 500;
  color: var(--mantine-color-bright);
}

.m-3de554dd {
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);
  padding-top: 0;
}

.m-e4d36c9b:where([data-type='radial']) :where(.m-3de554dd) {
    padding-top: var(--mantine-spacing-sm);
  }

.m-3de8964e {
  font-size: var(--mantine-font-size-sm);
  display: flex;
  justify-content: space-between;
}

.m-3de8964e:where(.m-3de8964e + .m-3de8964e) {
    margin-top: calc(var(--mantine-spacing-sm) / 2);
  }

.m-50186d10 {
  display: flex;
  align-items: center;
  gap: var(--mantine-spacing-sm);
  margin-inline-end: var(--mantine-spacing-xl);
}

.m-501dadf9 {
  color: var(--mantine-color-text);
  font-size: var(--mantine-font-size-sm);
}

.m-50192318 {
  color: var(--mantine-color-bright);
}

.m-847eaf {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

  .m-847eaf:where([data-position='top']) {
    padding-bottom: var(--mantine-spacing-md);
  }

  .m-847eaf:where([data-position='bottom']) {
    padding-top: var(--mantine-spacing-md);
  }

.m-17da7e62 {
  display: flex;
  align-items: center;
  gap: calc(0.4375rem * var(--mantine-scale));
  padding: calc(0.4375rem * var(--mantine-scale)) var(--mantine-spacing-xs);
  border-radius: var(--mantine-radius-default);
  line-height: 1;
}

@media (hover: hover) {
    :where([data-mantine-color-scheme='light']) .m-17da7e62:where(:hover) {
      background-color: var(--mantine-color-gray-0);
  }

    :where([data-mantine-color-scheme='dark']) .m-17da7e62:where(:hover) {
      background-color: var(--mantine-color-dark-5);
  }
}

@media (hover: none) {
    :where([data-mantine-color-scheme='light']) .m-17da7e62:where(:active) {
      background-color: var(--mantine-color-gray-0);
  }

    :where([data-mantine-color-scheme='dark']) .m-17da7e62:where(:active) {
      background-color: var(--mantine-color-dark-5);
  }
}

.m-8ff56c0d {
  font-size: var(--mantine-font-size-sm);
  margin: 0;
  padding: 0;
}

.m-a50f3e58 {
  display: block;
  width: 100%;
}

  :where([data-mantine-color-scheme='light']) .m-a50f3e58 {
    --chart-grid-color: rgba(173, 181, 189, 0.6);
    --chart-cursor-fill: rgba(173, 181, 189, 0.15);
}

  :where([data-mantine-color-scheme='dark']) .m-a50f3e58 {
    --chart-cursor-fill: rgba(105, 105, 105, 0.15);
    --chart-grid-color: rgba(105, 105, 105, 0.6);
}

  .m-a50f3e58 :where(text) {
    font-family: var(--mantine-font-family);
    color: var(--chart-text-color, var(--mantine-color-dimmed));
  }

.m-af9188cb {
  height: 100%;
  width: 100%;
}

.m-a50a48bc {
  stroke: var(--chart-grid-color);
}

.m-a507a517 {
  color: var(--chart-grid-color);
}

.m-a410e613 {
  min-height: var(--chart-size, auto);
  height: var(--chart-size, auto);
  width: var(--chart-size, auto);
  min-width: var(--chart-size, auto);
}

  .m-a410e613 :where(*) {
    outline: 0;
  }

.m-ddb0bfe3 {
  fill: var(--mantine-color-text);
  font-size: var(--mantine-font-size-sm);
}

.m-cd8943fd {
  min-height: var(--chart-size, auto);
  height: var(--chart-size, auto);
  width: var(--chart-size, auto);
  min-width: var(--chart-size, auto);
}

  .m-cd8943fd :where(*) {
    outline: 0;
  }

.m-e3441f39 {
  fill: var(--mantine-color-white);
  font-size: var(--mantine-font-size-xs);
}

:where([data-mantine-color-scheme='light']) .m-1f271cf7 {
    --chart-grid-color: rgba(173, 181, 189, 0.6);
    --chart-text-color: var(--mantine-color-gray-7);
}
  :where([data-mantine-color-scheme='dark']) .m-1f271cf7 {
    --chart-grid-color: rgba(105, 105, 105, 0.6);
    --chart-text-color: var(--mantine-color-dark-1);
}

  .m-1f271cf7 :where(text) {
    font-size: var(--mantine-font-size-xs);
    fill: var(--chart-text-color, var(--mantine-color-dimmed));
    user-select: none;
  }

.m-cf06f58c {
  height: 100%;
  width: 100%;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_abcd1a';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_abcd1a';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_abcd1a {font-family: '__Inter_abcd1a', '__Inter_Fallback_abcd1a', sans-serif;font-style: normal
}

/* latin-ext */
@font-face {
  font-family: '__Jolly_Lodger_1066c0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5d9b00bbe3fec68e-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Jolly_Lodger_1066c0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5350e8e00d4ee253-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Jolly_Lodger_Fallback_1066c0';src: local("Arial");ascent-override: 149.87%;descent-override: 38.98%;line-gap-override: 0.00%;size-adjust: 64.38%
}.__className_1066c0 {font-family: '__Jolly_Lodger_1066c0', '__Jolly_Lodger_Fallback_1066c0', sans-serif;font-weight: 400;font-style: normal
}

.m-468e7eda {
  padding-top: 0;
  padding-bottom: 0;
  appearance: none;
}

  .m-468e7eda::-webkit-calendar-picker-indicator {
    display: none;
  }

  .m-468e7eda::-webkit-clear-button {
    display: none;
  }

  .m-468e7eda::-webkit-datetime-edit-hour-field,
  .m-468e7eda::-webkit-datetime-edit-minute-field,
  .m-468e7eda::-webkit-datetime-edit-second-field {
    padding-top: 0;
    max-height: calc(1.875rem * var(--mantine-scale));
    display: inline;
  }

  .m-468e7eda::-webkit-datetime-edit-hour-field:focus, .m-468e7eda::-webkit-datetime-edit-minute-field:focus, .m-468e7eda::-webkit-datetime-edit-second-field:focus {
      background-color: var(--mantine-primary-color-filled);
      color: var(--mantine-color-white);
    }

.m-396ce5cb {
  --day-size-xs: calc(1.875rem * var(--mantine-scale));
  --day-size-sm: calc(2.25rem * var(--mantine-scale));
  --day-size-md: calc(2.625rem * var(--mantine-scale));
  --day-size-lg: calc(3rem * var(--mantine-scale));
  --day-size-xl: calc(3.375rem * var(--mantine-scale));
  --day-size: var(--day-size-sm);

  width: var(--day-size, calc(2.25rem * var(--mantine-scale)));
  height: var(--day-size, calc(2.25rem * var(--mantine-scale)));
  font-size: calc(var(--day-size) / 2.8);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: var(--mantine-radius-default);
  color: var(--mantine-color-text);
  opacity: 1;
}

  @media (hover: hover) {
      [data-mantine-color-scheme='light'] .m-396ce5cb:hover:where(:not([data-static], [data-disabled], [data-selected], [data-in-range])) {
        background-color: var(--mantine-color-gray-0);
  }

      [data-mantine-color-scheme='dark'] .m-396ce5cb:hover:where(:not([data-static], [data-disabled], [data-selected], [data-in-range])) {
        background-color: var(--mantine-color-dark-5);
  }
}

  @media (hover: none) {
      [data-mantine-color-scheme='light'] .m-396ce5cb:active:where(:not([data-static], [data-disabled], [data-selected], [data-in-range])) {
        background-color: var(--mantine-color-gray-0);
  }

      [data-mantine-color-scheme='dark'] .m-396ce5cb:active:where(:not([data-static], [data-disabled], [data-selected], [data-in-range])) {
        background-color: var(--mantine-color-dark-5);
  }
}

  .m-396ce5cb:where([data-static]) {
    user-select: auto;
    cursor: default;
  }

  .m-396ce5cb:where([data-weekend]) {
    color: var(--mantine-color-red-6);
  }

  .m-396ce5cb:where([data-outside]) {
    color: var(--mantine-color-dimmed);
    opacity: 0.5;
  }

  .m-396ce5cb:where(:disabled, [data-disabled]) {
    color: var(--mantine-color-dimmed);
    cursor: not-allowed;
    opacity: 0.5;
  }

  .m-396ce5cb:where([data-hidden]) {
    display: none;
  }

  .m-396ce5cb:where([data-in-range]) {
    background-color: var(--mantine-primary-color-light-hover);
    border-radius: 0;
  }

  @media (hover: hover) {
      .m-396ce5cb:where([data-in-range]):hover:where(:not([data-disabled], [data-static])) {
        background-color: var(--mantine-primary-color-light);
      }
}

  @media (hover: none) {
      .m-396ce5cb:where([data-in-range]):active:where(:not([data-disabled], [data-static])) {
        background-color: var(--mantine-primary-color-light);
      }
}

  .m-396ce5cb:where([data-first-in-range]) {
    border-radius: 0;
    border-start-start-radius: var(--mantine-radius-default);
    border-end-start-radius: var(--mantine-radius-default);
  }

  .m-396ce5cb:where([data-last-in-range]) {
    border-radius: 0;
    border-end-end-radius: var(--mantine-radius-default);
    border-start-end-radius: var(--mantine-radius-default);
  }

  .m-396ce5cb:where([data-last-in-range][data-first-in-range]) {
    border-radius: var(--mantine-radius-default);
  }

  .m-396ce5cb:where([data-selected]) {
    background-color: var(--mantine-primary-color-filled);
    color: var(--mantine-primary-color-contrast);
  }

  @media (hover: hover) {
      .m-396ce5cb:where([data-selected]):hover:where(:not([data-disabled], [data-static])) {
        background-color: var(--mantine-primary-color-filled-hover);
      }
}

  @media (hover: none) {
      .m-396ce5cb:where([data-selected]):active:where(:not([data-disabled], [data-static])) {
        background-color: var(--mantine-primary-color-filled-hover);
      }
}

.m-18a3eca {
  color: var(--mantine-color-dimmed);
  font-weight: normal;
  font-size: var(--wr-fz, var(--mantine-font-size-sm));
  text-transform: capitalize;
  padding-bottom: calc(var(--wr-spacing, var(--mantine-spacing-sm)) / 2);
}

.m-cc9820d3 {
  border-collapse: collapse;
  table-layout: fixed;
}

.m-8f457cd5 {
  padding: 0;
}

.m-8f457cd5:where([data-with-spacing]) {
    padding: calc(0.03125rem * var(--mantine-scale));
  }

.m-dc6a3c71 {
  --dpc-size-xs: calc(1.875rem * var(--mantine-scale));
  --dpc-size-sm: calc(2.25rem * var(--mantine-scale));
  --dpc-size-md: calc(2.625rem * var(--mantine-scale));
  --dpc-size-lg: calc(3rem * var(--mantine-scale));
  --dpc-size-xl: calc(3.375rem * var(--mantine-scale));
  --dpc-size: var(--dpc-size-sm);

  font-size: var(--dpc-fz, var(--mantine-font-size-sm));
  height: var(--dpc-size);
  width: calc((var(--dpc-size) * 7) / 3 + calc(0.09375rem * var(--mantine-scale)));
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  background-color: transparent;
  color: var(--mantine-color-text);
  opacity: 1;
  border-radius: var(--mantine-radius-default);
}

  @media (hover: hover) {
      :where([data-mantine-color-scheme='light']) .m-dc6a3c71:hover:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m-dc6a3c71:hover:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-dark-5);
  }
}

  @media (hover: none) {
      :where([data-mantine-color-scheme='light']) .m-dc6a3c71:active:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m-dc6a3c71:active:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-dark-5);
  }
}

  .m-dc6a3c71:where(:disabled, [data-disabled]) {
    color: var(--mantine-color-dimmed);
    cursor: not-allowed;
    opacity: 0.5;
  }

  .m-dc6a3c71:where([data-selected]) {
    background-color: var(--mantine-primary-color-filled);
    color: var(--mantine-primary-color-contrast, var(--mantine-color-white));
  }

  @media (hover: hover) {

  .m-dc6a3c71:where([data-selected]):hover {
      background-color: var(--mantine-primary-color-filled-hover);
  }
}

  @media (hover: none) {

  .m-dc6a3c71:where([data-selected]):active {
      background-color: var(--mantine-primary-color-filled-hover);
  }
}

  .m-dc6a3c71:where([data-in-range]) {
    background-color: var(--mantine-primary-color-light-hover);
    border-radius: 0;
  }

  @media (hover: hover) {

  .m-dc6a3c71:where([data-in-range]):hover {
      background-color: var(--mantine-primary-color-light);
  }
}

  @media (hover: none) {

  .m-dc6a3c71:where([data-in-range]):active {
      background-color: var(--mantine-primary-color-light);
  }
}

  .m-dc6a3c71:where([data-first-in-range]) {
    border-radius: 0;
    border-start-start-radius: var(--mantine-radius-default);
    border-end-start-radius: var(--mantine-radius-default);
  }

  .m-dc6a3c71:where([data-last-in-range]) {
    border-radius: 0;
    border-end-end-radius: var(--mantine-radius-default);
    border-start-end-radius: var(--mantine-radius-default);
  }

  .m-dc6a3c71:where([data-first-in-range][data-last-in-range]) {
    border-radius: var(--mantine-radius-default);
  }

.m-9206547b {
  border-collapse: collapse;
  border-width: 0;
}

.m-c5a19c7d {
  padding: 0;
}

.m-c5a19c7d:where([data-with-spacing]) {
    padding: calc(0.03125rem * var(--mantine-scale));
  }

.m-2a6c32d {
  border-collapse: collapse;
  border-width: 0;
  cursor: pointer;
}

.m-fe27622f {
  padding: 0;
}

.m-fe27622f:where([data-with-spacing]) {
    padding: calc(0.03125rem * var(--mantine-scale));
  }

.m-730a79ed {
  --dch-control-size-xs: calc(1.875rem * var(--mantine-scale));
  --dch-control-size-sm: calc(2.25rem * var(--mantine-scale));
  --dch-control-size-md: calc(2.625rem * var(--mantine-scale));
  --dch-control-size-lg: calc(3rem * var(--mantine-scale));
  --dch-control-size-xl: calc(3.375rem * var(--mantine-scale));
  --dch-control-size: var(--dch-control-size-sm);

  display: flex;
  max-width: calc(var(--dch-control-size) * 7 + calc(0.4375rem * var(--mantine-scale)));
  margin-bottom: var(--mantine-spacing-xs);
}

.m-f6645d97,
.m-2351eeb0 {
  height: var(--dch-control-size);
  border-radius: var(--mantine-radius-default);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  opacity: 1;
  cursor: pointer;
}

@media (hover: hover) {
      [data-mantine-color-scheme='light'] .m-f6645d97:hover:where(:not([data-disabled], :disabled)), [data-mantine-color-scheme='light'] .m-2351eeb0:hover:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-gray-0);
  }

      [data-mantine-color-scheme='dark'] .m-f6645d97:hover:where(:not([data-disabled], :disabled)), [data-mantine-color-scheme='dark'] .m-2351eeb0:hover:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-dark-5);
  }
}

@media (hover: none) {
      [data-mantine-color-scheme='light'] .m-f6645d97:active:where(:not([data-disabled], :disabled)), [data-mantine-color-scheme='light'] .m-2351eeb0:active:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-gray-0);
  }

      [data-mantine-color-scheme='dark'] .m-f6645d97:active:where(:not([data-disabled], :disabled)), [data-mantine-color-scheme='dark'] .m-2351eeb0:active:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-dark-5);
  }
}

.m-f6645d97:where(:disabled, [data-disabled]), .m-2351eeb0:where(:disabled, [data-disabled]) {
    opacity: 0.2;
    cursor: not-allowed;
  }

.m-2351eeb0 {
  width: var(--dch-control-size);
}

.m-f6645d97 {
  flex: 1;
  font-size: var(--dch-fz, var(--mantine-font-size-sm));
  font-weight: 500;
  text-transform: capitalize;
}

.m-367dc749 {
  width: 60%;
  height: 60%;
}

.m-367dc749:where([data-direction='next']) {
    transform: rotate(270deg);
  }

:where([dir="rtl"]) .m-367dc749:where([data-direction='next']) {
      transform: rotate(90deg);
}

.m-367dc749:where([data-direction='previous']) {
    transform: rotate(90deg);
  }

:where([dir="rtl"]) .m-367dc749:where([data-direction='previous']) {
      transform: rotate(270deg);
}

.m-30b26e33 {
  display: flex;
  gap: var(--mantine-spacing-md);
}

.m-6fa5e2aa {
  cursor: pointer;
  line-height: unset;
}

  .m-6fa5e2aa:where([data-read-only]) {
    cursor: default;
  }

.m-208d2562 {
  display: flex;
  align-items: stretch;
  margin-top: var(--mantine-spacing-md);
}

.m-62ee059 {
  flex: 1;
  margin-inline-end: var(--mantine-spacing-md);
}

.m-b37d9ac7 {
  width: calc(100% - var(--mantine-spacing-md) * 2);
  position: fixed;
  z-index: var(--notifications-z-index);
  top: var(--notifications-top);
  left: var(--notifications-left);
  right: var(--notifications-right);
  bottom: var(--notifications-bottom);
  transform: var(--notifications-transform);
  max-width: var(--notifications-container-width);
}

.m-5ed0edd0 + .m-5ed0edd0 {
    margin-top: var(--mantine-spacing-md);
  }

.m-d2b315db[data-scrollable] {
    --spotlight-content-height: 100%;
  }

.m-3cd250e0 {
  overflow: hidden;
  height: var(--spotlight-content-height, auto);
  max-height: var(--spotlight-max-height);
}

.m-d2abce9b {
  display: flex;
  flex-direction: column;
  height: var(--spotlight-content-height, auto);
}

.m-f366a061 {
  border: 0;
  background-color: transparent;
}

.m-6e463822 {
  /* Used in child ScrollArea */
  --spotlight-actions-list-padding: calc(0.25rem * var(--mantine-scale));

  border-top: calc(0.0625rem * var(--mantine-scale)) solid;
  flex: 1;
  padding: var(--spotlight-actions-list-padding);
  max-height: calc(100% - calc(3.125rem * var(--mantine-scale)));
  margin-inline-end: calc(var(--spotlight-actions-list-padding) * -1);
}

:where([data-mantine-color-scheme='light']) .m-6e463822 {
    border-color: var(--mantine-color-gray-2);
}

:where([data-mantine-color-scheme='dark']) .m-6e463822 {
    border-color: var(--mantine-color-dark-4);
}

.m-d49bb8ef {
  display: flex;
  align-items: center;
  width: 100%;
  padding: calc(0.4375rem * var(--mantine-scale)) var(--mantine-spacing-md);
  border-radius: var(--mantine-radius-default);
  background-color: transparent;
  color: inherit;
  text-align: left;
}

:where([dir="rtl"]) .m-d49bb8ef {
    text-align: right;
}

@media (hover: hover) {
      :where([data-mantine-color-scheme='light']) .m-d49bb8ef:where(:not([data-selected])):hover {
        background-color: var(--mantine-color-gray-0);
    }

      :where([data-mantine-color-scheme='dark']) .m-d49bb8ef:where(:not([data-selected])):hover {
        background-color: var(--mantine-color-dark-6);
    }
}

@media (hover: none) {
      :where([data-mantine-color-scheme='light']) .m-d49bb8ef:where(:not([data-selected])):active {
        background-color: var(--mantine-color-gray-0);
    }

      :where([data-mantine-color-scheme='dark']) .m-d49bb8ef:where(:not([data-selected])):active {
        background-color: var(--mantine-color-dark-6);
    }
}

.m-d49bb8ef:where([data-selected]) {
    background-color: var(--mantine-primary-color-filled);
    color: var(--mantine-primary-color-contrast, var(--mantine-color-white));
    --action-description-color: var(--mantine-primary-color-contrast, var(--mantine-color-white));
    --action-description-opacity: 0.7;
  }

.m-3d475731 {
  flex: 1;
}

.m-832642f6 {
  opacity: 1;
  color: inherit;
}

.m-832642f6:where([data-dimmed]) {
    opacity: var(--action-description-opacity, 1);
    color: var(--action-description-color, var(--mantine-color-dimmed));
  }

.m-832642f6:where([data-position='left']) {
    margin-inline-end: var(--mantine-spacing-md);
  }

.m-832642f6:where([data-position='right']) {
    margin-inline-start: var(--mantine-spacing-md);
  }

.m-832642f6 > svg {
    display: block;
  }

.m-6c2a1345 {
  display: block;
}

.m-a6d9d78d {
  display: block;
  font-size: var(--mantine-font-size-xs);
  opacity: var(--action-description-opacity, 1);
  color: var(--action-description-color, var(--mantine-color-dimmed));
}

.m-82f78f74 {
  color: var(--mantine-color-dimmed);
  padding: var(--mantine-spacing-md);
  text-align: center;
}

.m-ddcaf054 {
  padding: var(--mantine-spacing-xs) calc(var(--mantine-spacing-md) + calc(0.25rem * var(--mantine-scale)));
  border-top: calc(0.0625rem * var(--mantine-scale)) solid;
}

:where([data-mantine-color-scheme='light']) .m-ddcaf054 {
    border-color: var(--mantine-color-gray-2);
}

:where([data-mantine-color-scheme='dark']) .m-ddcaf054 {
    border-color: var(--mantine-color-dark-4);
}

.m-5a3e5f7b {
  display: flex;
  flex-direction: column;
  margin-top: var(--mantine-spacing-md);
}

.m-5a3e5f7b::before {
    content: var(--spotlight-label);
    text-transform: uppercase;
    font-size: var(--mantine-font-size-xs);
    font-weight: 700;
    color: var(--mantine-color-dimmed);
    padding-inline-start: var(--mantine-spacing-md);
  }

.m-5a3e5f7b:empty {
    display: none;
  }

[data-mantine-color-scheme='dark'] .SchemeControl_root__3FOcg {
    background-color: var(--mantine-color-dark-7)
}

.Shell_root__bKz9r {
  height: 100%;
}

[data-mantine-color-scheme='light'] .Shell_main__TPV_F {
    background-color: var(--mantine-color-gray-0);
}

[data-mantine-color-scheme='dark'] .Shell_main__TPV_F {
    background-color: var(--mantine-color-dark-8);
}

[data-mantine-color-scheme='light'] .Shell_header__xx_XW {
    background-color: var(--mantine-color-body);
    border-color: var(--mantine-color-gray-0);
}

[data-mantine-color-scheme='dark'] .Shell_header__xx_XW {
    background-color: var(--mantine-color-body);
    border-color: var(--mantine-color-dark-8);
}

[data-mantine-color-scheme='light'] .Shell_navbar__Lpa9D {
    background-color: var(--mantine-color-gray-0);
    border-color: var(--mantine-color-body);
}

[data-mantine-color-scheme='dark'] .Shell_navbar__Lpa9D {
    background-color: var(--mantine-color-dark-8);
    border-color: var(--mantine-color-body);
}

